<template>
  <div>
    <TitleMore :title="['应急救护课程']" :isMore="false"></TitleMore>
    <div class="baseCard classCardMain">
      <div class="classCardContent">
        <el-row>
          <el-col :span="8"
            v-for="(item, index) in ListData"
            :key="index"
            class="unit"
          >
            
          <div
            class="classCard"
            @click="$goto('/classDetail', { id: item.id })"
          >
            <img
              v-show="item.kc_state == '报名中'"
              class="statusImg"
              src="../../assets/img/applying.png"
              alt=""
            />
            <img
              v-show="item.kc_state == '培训中'"
              class="statusImg"
              src="../../assets/img/training.png"
              alt=""
            />
            <img
              v-show="item.kc_state == '已结束'"
              class="statusImg"
              src="../../assets/img/end.png"
              alt=""
            />
            <img
              style="width: 100%; height: 196px"
              :src="item.smallImage"
              alt=""
            />
            <p
              style="
                font-size: 16px;
                font-weight: bold;
                color: #333333;
                line-height: 30px;
                text-align: center;
								height: 60px;
								display: -webkit-box;
								line-clamp: 2;
								overflow: hidden;
              "
            >
              {{ item.title }}
            </p>
            <div class="itemText">
              <p>
                <img src="../../assets/img/list-little.png" alt="" />
                <span class="itemTextContent">已报名人数</span>
              </p>
              <p class="rescueText">{{ item.has_enroll_count }}</p>
            </div>
            <div class="itemText">
              <p>
                <img src="../../assets/img/list-little.png" alt="" />
                <span class="itemTextContent">预计培训时间</span>
              </p>
              <p class="rescueText">{{ item.estimate_train_date }}</p>
            </div>
            <div class="itemText">
              <p>
                <img src="../../assets/img/list-little.png" alt="" />
                <span class="itemTextContent">预计培训人数</span>
              </p>
              <p class="rescueText">{{ item.estimate_train_count }}人</p>
            </div>
          </div>
          </el-col>
        </el-row>
      </div>
      <el-pagination
        :current-page="page"
        :page-sizes="[10, 20, 30, 40]"
        :page-size="size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import TitleMore from "@components/TitleMore";
import apiUrls from "@api";
export default {
  data() {
    return {
      page: 0,
      size: 10,
      total: 0,
      ListData: [],
    };
  },

  components: {
    TitleMore,
  },

  mounted() {
    this.getList();
  },

  methods: {
    handleSizeChange(val) {},
    handleCurrentChange(val) {},
    getList() {
      apiUrls
        .selfDynamicList({ node: "yjjhkclb", page: this.page, size: this.size })
        .then((res) => {
          this.ListData = res.results.data;
          this.total = res.results.pager.totalElements;
        });
    },
  },
};
</script>
<style lang="less" scoped>
.classCardMain {
  display: flex;
  flex-direction: column;

  .classCardContent {
    margin-bottom: 20px;
  }
  .unit{
    padding: 12px;
  }
  .classCard {
    position: relative;
    width: 320px;
    background-color: #ffffff;
    box-shadow: 0px 0px 5px 5px #e9e9e9;
    padding: 20px;
    margin: 0 auto;
    cursor: pointer;
    .statusImg {
      position: absolute;
      right: 0px;
      top: 0px;
    }
    .itemText {
      padding: 10px 0px;
    }
  }
}
</style>